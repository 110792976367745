import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "challenge"
    }}>{`Challenge`}</h1>
    <p>{`Kiran has a problem. He's working on a project and doesn't know how. Let's help`}</p>
    <h1 {...{
      "id": "my-solution"
    }}>{`My Solution`}</h1>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/_tNeyToiCsI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <iframe src="https://codesandbox.io/embed/14w5j15kol?fontsize=14" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/kiran_gaurang"
      }}>{`Kiran`}</a>{` wants to build a "circle with arcs"
chart, but he's having trouble. He asked for help so here we are :)`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/Swizec/datavizAdvent/raw/master/src/content/kiran-challenge/ask-for-help.png",
        "alt": null
      }}></img></p>
    <p>{`I livecoded this one from the Paris airport so there's no sound in the video. I
was too shy to narrate my actions in the middle of a busy Starbucks. Maybe next
time.`}</p>
    <p>{`Anyway, to build an arc circle like this, we can take many cues from how you
would build a piechart. Arcs are still arcs: They're round, have an inner and
outer radius, and represent a datapoint. We can layer them on top of each other
with a band scale feeding into radiuses.`}</p>
    <p>{`Like this 👇`}</p>
    <h2 {...{
      "id": "first-you-need-a-dataset"
    }}>{`First you need a dataset`}</h2>
    <p>{`We fake the dataset because Kiran didn't provide one.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// 5 percentages represent our dataset
const data = d3.range(5).map(_ => ({
  name: Faker.hacker.verb(),
  percentage: 75 * Math.random(),
}));
`}</code></pre>
    <p>{`5 datapoints, fake name with faker, and a random chunk out of 75%. Tried going
full 100 at first and it didn't look great at all.`}</p>
    <h2 {...{
      "id": "then-you-need-a-parent-component"
    }}>{`Then you need a parent component`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const CircleArcs = ({ data, maxR }) => {
  const rScale = d3
    .scaleBand()
    .paddingInner(0.4)
    .paddingOuter(1)
    .domain(d3.range(data.length))
    .range([0, maxR]);

  return (
    <g>
      <Circle cx={0} cy={0} r={maxR} />
      {data.map((d, i) => (
        <Arc d={d} r={rScale(i)} width={rScale.bandwidth()} key={i} />
      ))}
    </g>
  );
};
`}</code></pre>
    <p>{`A functional component will do. Create a band scale for the radiuses. Those cut
up a given space into equal bands and let you define padding. Same scale you'd
use for a barchart to position the bars.`}</p>
    <p>{`The band scale is ordinal so our domain has to match the number of inputs,
`}<inlineCode parentName="p">{`d3.range`}</inlineCode>{` takes care of that. For our dataset that sets the domain to
`}<inlineCode parentName="p">{`[0,1,2,3,4]`}</inlineCode>{`.`}</p>
    <p>{`Scale range goes from zero to max radius.`}</p>
    <p>{`Render a `}<inlineCode parentName="p">{`<Circle>`}</inlineCode>{` which is a styled circle component, loop through the data
and render an `}<inlineCode parentName="p">{`<Arc>`}</inlineCode>{` component for each entry. The arc takes data in the `}<inlineCode parentName="p">{`d`}</inlineCode>{`
prop, call `}<inlineCode parentName="p">{`rScale`}</inlineCode>{` to get the radius, and use `}<inlineCode parentName="p">{`rScale.bandwidth()`}</inlineCode>{` to define
the width. Band scales calculate optimal widths on their own.`}</p>
    <p>{`We can use index for keys because we know arcs will never re-order.`}</p>
    <h2 {...{
      "id": "the-parent-component-needs-arcs"
    }}>{`The parent component needs arcs`}</h2>
    <p>{`That's what it's rendering. They look like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Arc = ({ d, r, width }) => {
  const arc = d3
    .arc()
    .innerRadius(r)
    .outerRadius(r + width)
    .startAngle(0)
    .endAngle((d.percentage / 100) * (Math.PI * 2));

  return (
    <g>
      <Label y={-r} x={-10}>
        {d.name}
      </Label>
      <ArcPath d={arc()} />
    </g>
  );
};
`}</code></pre>
    <p>{`A D3 arc generator defines the path shape of our arcs. Inner radius comes from
the `}<inlineCode parentName="p">{`r`}</inlineCode>{` prop, outer radius is `}<inlineCode parentName="p">{`r+width`}</inlineCode>{`. Unlike a traditional pie chart, every
arc starts at angle zero.`}</p>
    <p>{`The end angle makes our arcs communicate their value. A percentage of full
circle.`}</p>
    <p>{`Each arc also comes with a label at its start. We position those at the
beginning of the arc using the `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` props. Setting their anchor point as
`}<inlineCode parentName="p">{`end`}</inlineCode>{` automatically makes them `}<em parentName="p">{`end`}</em>{` at that point.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const ArcPath = styled.path\`
  fill: white;
\`;

const Label = styled.text\`
  fill: white;
  text-anchor: end;
\`;
`}</code></pre>
    <p>{`Styled components work great for setting pretty much any SVG prop 👌`}</p>
    <p>{`And the result is a circle arc chart thing. Wonderful.`}</p>
    <blockquote className="twitter-tweet" data-lang="en"><p lang="en" dir="ltr">For <a href="https://twitter.com/hashtag/ReactVizHoliday?src=hash&amp;ref_src=twsrc%5Etfw">#ReactVizHoliday</a> Day 14 we solved the <a href="https://twitter.com/kiran_gaurang?ref_src=twsrc%5Etfw">@kiran_gaurang</a> challenge: How do you build a arc circle chart thing<br /><br />Solved live from the Paris airport because free wifi in France gets 3000kb/s upload 👌<br /><br />👉 <a href="https://t.co/fKD856bWMp">https://t.co/fKD856bWMp</a> <a href="https://t.co/QwDAS7L63o">pic.twitter.com/QwDAS7L63o</a></p>&mdash; Swizec Teller (@Swizec) <a href="https://twitter.com/Swizec/status/1076448516619362304?ref_src=twsrc%5Etfw">December 22, 2018</a></blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      